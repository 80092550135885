import React, { useState, useEffect } from 'react';
import { StatusTag } from '@shared/ui';
import {
   Space,
   Spin,
   Button,
   Typography,
   Tag,
   Collapse,
   Divider,
   Alert
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
   DownloadOutlined,
   CheckOutlined,
   LoadingOutlined,
   CloseOutlined
} from '@ant-design/icons';

import { GetLeadList } from '@features/list-lead/model/GetLeadList';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';
import ListLeadsOfRoute from '@features/list-route/ui/ListLeadsOfRoute';
import { getLeadConfirmById } from '@shared/api/all/lead';
import ModalButtonDeal from './ModalButtonDeal';
const { Text } = Typography;

const getText = (status) => {
   switch (status) {
      case 'confirmed':
         return 'Подтверждено';
      case 'notconfirmed':
         return 'Не подтверждено';
      case null:
         return 'Нет ответа';
      default:
         return 'Пусто';
   }
};

const LeadConfirm = ({ id, callback, simple = false }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);

   useEffect(() => {
      fetchData(id);
   }, [id]);

   const fetchData = (id) => {
      setLoading(true);
      getLeadConfirmById(id)
         .then((res) => {
            const data = res.json.data;

            data.dealObj = getDealStatus(data);

            setData([data]);
            setLoading(false);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   const closeModal = () => {
      fetchData(id);
      callback();
   };

   const getDealStatus = (item) => {
      let valueSeller =
         item.statusSeller === 'confirmed'
            ? 1
            : item.statusSeller === 'notconfirmed'
            ? 0
            : -1;

      let valueDriver =
         item.statusDriver === 'confirmed'
            ? 1
            : item.statusDriver === 'notconfirmed'
            ? 0
            : -1;

      let valueKey = valueSeller.toString() + valueDriver.toString();

      let message = '';

      const valueKeys = {
         '11': { message: 'Успешно', status: 'success' },
         '00': { message: 'Не состоялась', status: 'error' },
         '01': { message: 'Продавец - Покупатель +', status: 'error' },
         '0-1': { message: 'Продавец - Покупатель б/о', status: 'error' },
         '10': { message: 'Продавец + Покупатель -', status: 'error' },
         '1-1': { message: 'Продавец + Покупатель б/о', status: 'error' },
         '-11': { message: 'Продавец б/о Покупатель +', status: 'error' },
         '-10': { message: 'Продавец б/о Покупатель -', status: 'error' },
         '-1-1': { message: 'б/о 2п', status: 'error' }
      };

      return {
         status: valueKeys[valueKey].status,
         message: `Сделка: ${valueKeys[valueKey].message}`
      };
   };

   if (loading) {
      return <Spin />;
   }

   if (!data?.[0]?.leadId) {
      return <></>;
   }

   if (simple) {
      return (
         <>
            {data.map((item, index) => (
               <>
                  <div>
                     <Space
                        direction="horizontal"
                        style={{ fontSize: '12px', marginBottom: 0 }}>
                        {item.dealObj.message}
                     </Space>
                     <div>
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                           {`Продавец: ${getText(item.statusSeller)}`},
                           {` Водитель: ${getText(item.statusDriver)}`}
                        </Text>
                     </div>
                  </div>
               </>
            ))}
         </>
      );
   }

   return (
      <Space direction="vertical" style={{ width: '100%', marginBottom: '15px' }}>
         {data.map((item, index) => (
            <>
               <Alert
                  message={item.dealObj.message}
                  type={item.dealObj.status}
                  showIcon
               />

               <Alert
                  message={`Продавец: ${getText(item.statusSeller)}`}
                  showIcon
                  type={item.statusSeller === 'confirmed' ? 'success' : 'error'}
               />

               <Alert
                  message={`Водитель: ${getText(item.statusDriver)}`}
                  showIcon
                  type={item.statusDriver === 'confirmed' ? 'success' : 'error'}
               />
            </>
         ))}

         <ModalButtonDeal id={id} closeModal={closeModal} />
      </Space>
   );
};

export default LeadConfirm;
