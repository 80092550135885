import React, { useState, useEffect } from 'react';
import { Spin, Timeline, Typography, Button, Tag, Divider } from 'antd';
import { getLeadStatusesById, getRouteStatusesById } from '@shared/api/all/statuses';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';

import { useNavigate } from 'react-router-dom';
import { StatusProgress } from '@shared/ui';
import { StatusTag } from '@shared/ui';

const { Text, Link } = Typography;

const getTimeLineElements = (data, entityType, createdAt, navigate) => {
   const dates = [];

   const settings = settingsOfStatuses(entityType);
   const rr = data;

   if (rr && rr?.length > 0) {
      for (let i = 0; i < rr.length; i++) {
         dates.push({
            children: (
               <>
                  <div style={{ whiteSpace: 'nowrap' }}>
                     <StatusTag status={rr[i]?.status} type="lead" />
                     {/* ${settings?.[rr[i]?.status]?.label}  */}

                     {rr[i]?.driverId ? (
                        <span
                           className="green-span-url"
                           style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                           onClick={() => navigate(`/drivers/${rr[i].driverId}`)}>
                           {`Водитель: № ${rr[i]?.driverId}`}
                        </span>
                     ) : (
                        ''
                     )}

                     {`${timestampToNormalDDMMYY(
                        rr[i].createdAt
                     )} в ${timestampToNormalTime(rr[i].createdAt)}`}
                  </div>
                  {rr[i].comment ? (
                     <div>
                        <Text type="secondary" style={{ fontSize: '12px' }}>
                           {rr[i].comment}
                        </Text>
                     </div>
                  ) : null}
               </>
            ),

            color: settings?.[rr[i]?.status]?.color
         });
      }
   }

   dates.push({
      children: (
         <div style={{ whiteSpace: 'nowrap' }}>
            <>
               <Tag
                  className="status-tag"
                  style={{ color: '#fff', background: 'purple' }}>
                  Создан
               </Tag>
               {timestampToNormalDDMMYY(createdAt)} в{' '}
               {timestampToNormalTime(createdAt)}
            </>
         </div>
      ),
      color: 'purple'
   });

   dates.map((item, index) => {
      if (index !== 0) {
         item.color = '#a9a9a95c';
         item.style = { opacity: 0.6, filter: 'contrast(0.7)' };
      }
   });

   return dates;
};

const settingsOfStatuses = (type) => ({
   active: { label: 'Активен: ', color: '#20B2AA' },
   searching: { label: 'Поиск водителя: ', color: '#d5a85b' },
   onway: { label: 'Водитель в пути: ', color: '#5c5c5c' },
   editing: { label: 'Редактирование: ', color: '#6463f3' },
   waiting: { label: 'Ожидание вывоза: ', color: '#5c5c5c' },
   finished: { label: 'Завершен: ', color: '#6495ed' },
   blocked: { label: 'Блокировка: ', color: '#FA8072' },
   refused: { label: 'Отменен водителем: ', color: '#b22020' },
   inwork: { label: 'Водитель найден: ', color: '#000000' },
   inauction: { label: 'В торге: ', color: '#FF8C00' },
   wininauction: { label: 'Выигран в торгах: ', color: '#8B008B' },
   canceled: { label: 'Отменен продавцом: ', color: '#2075b2' },
   expired: { label: 'Просрочен ', color: '#A9A9A9' },
   deleted:
      type === 'lead'
         ? { label: 'Отменен менеджером: ', color: '#2055b2' }
         : {
              label: 'Удален: ',
              color: '#A9A9A9'
           }
});

const StatusesDateTimeLineXronology = ({
   entityId,
   entityType,
   createdAt,
   updatedAt,
   isReverse = false
}) => {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [showAll, setShowAll] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      fetchData(entityId);
   }, [entityId, updatedAt]);

   const fetchData = async (id) => {
      let rr = [];
      setLoading(true);

      rr =
         entityType === 'lead'
            ? await getLeadStatusesById(id)
                 .then((res) => res?.json?.data || [])
                 .catch((e) => console.error(e))
            : await getRouteStatusesById(id)
                 .then((res) => res?.json?.data || [])
                 .catch((e) => console.error(e));

      setLoading(false);
      setData(rr);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   if (data && data?.length === 0) {
      return <></>;
   }

   const loadData = isReverse ? data.reverse() : data;
   const timelineItems = getTimeLineElements(
      loadData,
      entityType,
      createdAt,
      navigate
   );
   const loadDataTimeLine = showAll
      ? timelineItems
      : [timelineItems[timelineItems.length - 1]];

   return (
      <>
         <Timeline
            items={loadDataTimeLine}
            className="dates-timeline"
            reverse={true}
         />

         {data?.length > 1 ? (
            <Link
               type="link"
               onClick={() => setShowAll((prev) => !prev)}
               style={{ fontSize: '11px' }}>
               {showAll ? 'Скрыть хрологию' : 'Развернуть хронологию'}
            </Link>
         ) : null}
      </>
   );
};

const StatusesDateTimeLine = ({
   createdAt,
   entityId,
   entityType,
   updatedAt,
   isReverse = false,
   isListMode = false,
   isShowProgress = true,
   isQueue = false
}) => {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [showAll, setShowAll] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      fetchData(entityId);
   }, [entityId, updatedAt]);

   const fetchData = async (id) => {
      let rr = [];
      setLoading(true);

      rr =
         entityType === 'lead'
            ? await getLeadStatusesById(id)
                 .then((res) => res?.json?.data || [])
                 .catch((e) => console.error(e))
            : await getRouteStatusesById(id)
                 .then((res) => res?.json?.data || [])
                 .catch((e) => console.error(e));

      setLoading(false);
      setData(rr);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   if (isListMode) {
      return (
         <>
            <Timeline
               items={getTimeLineElements(data, entityType, createdAt, navigate)}
               className="dates-timeline"
               reverse={isReverse}
            />
         </>
      );
   }

   if (showAll && data.length > 1) {
      return (
         <>
            <Timeline
               items={getTimeLineElements(data, entityType, createdAt, navigate)}
               className="dates-timeline"
               reverse={isReverse}
            />
            <Link
               type="link"
               onClick={() => setShowAll(false)}
               style={{ fontSize: '11px' }}>
               Скрыть хрологию
            </Link>
         </>
      );
   }

   if (!data?.[0]) {
      return <></>;
   }

   return (
      <>
         <StatusProgress
            status={data[0].status}
            type={entityType}
            showInfo={false}
            isQueue={isQueue}
            isShowProgress={isShowProgress}
         />

         {data[0].comment ? (
            <div style={{ marginTop: '5px' }}>
               <Text type="secondary" style={{ fontSize: '12px' }}>
                  {data[0].comment}
               </Text>
            </div>
         ) : null}

         {data.length > 1 ? (
            <div>
               <Link
                  type="link"
                  onClick={() => setShowAll(true)}
                  style={{ fontSize: '11px' }}>
                  Развернуть хронологию
               </Link>
            </div>
         ) : null}
      </>
   );
};

export { StatusesDateTimeLine, StatusesDateTimeLineXronology };
