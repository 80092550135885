import { getDriversExcel } from '@shared/api/all/driver';

export const GetDriverListExcel = async (params) => {
   try {
      const response = await getDriversExcel(params);
      if (!response.json) throw new Error();
      return response.json;
   } catch (e) {
      console.log(e);
   }
};
