import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getDriverById = (id) => get(`/driver/${id}`);

export const getDriverCoordinates = () => get(`/drivercoordinates`);
export const getDriverCoordinateById = (id) => get(`/drivercoordinate/${id}`);

export const getDriversExcel = (params) =>
   get(generateQueryParams(`/driverexcel`, params));

export const deleteDriverById = (id) => _delete(`/driver/${id}`, {}, true);

export const generateDriverPasswordById = (id) =>
   get(`/driver/${id}/generatePassword`);
export const getDrivers = (params) => get(generateQueryParams(`/drivers`, params));

export const createDriver = (fields) =>
   post(
      '/driver',
      {
         ...fields
      },
      true
   );

export const updateDriver = (fields, id) =>
   put(
      `/driver/${id}`,
      {
         ...fields
      },
      true
   );
