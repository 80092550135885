import React, { useState, useEffect } from 'react';
import {
   Space,
   Table,
   Tag,
   List,
   Tooltip,
   Switch,
   Badge,
   Dropdown,
   Descriptions,
   Alert
} from 'antd';

import { GetLeadList } from '../model/GetLeadList';
import { GetLeadListExcel } from '../model/GetLeadListExcel';

import { UpdateStatusOfLead } from '../model/UpdateStatusOfLead';
import { Button, VerticalSpace, DateWithAgo, StatusProgress } from '@shared/ui';
import DriverInfoByRouteId from '@features/list-driver/ui/DriverInfoByRouteId';
import TooltipTextWithEllipsis from '@shared/ui/Tooltips/TooltipTextWithEllipsis';
import ModalListOfNotes from '@features/notes/ui/ModalListOfNotes';
import {
   EditOutlined,
   StopOutlined,
   ClockCircleOutlined,
   ArrowRightOutlined,
   DashOutlined,
   SettingOutlined,
   CopyOutlined,
   BookOutlined,
   LogoutOutlined,
   ExportOutlined,
   UnorderedListOutlined,
   BarsOutlined,
   LoadingOutlined
} from '@ant-design/icons';

import LeadConfirm from '@features/list-lead/ui/LeadConfirm';
import LeadTimesList from '@shared/ui/LeadTimesList';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalButtonLeadCreate from './ModalButtonLeadCreate';
import { statusesOfLeads } from '@shared/const/statuses';
import { unitSettings } from '@shared/const/units';
import ModalButtonLeadsMap from './ModalButtonLeadsMap';
import { statusesOfDrivers } from '@shared/const/statuses';
import TooltipCustom from '@shared/ui/Tooltips/TooltipCustom';
import { getEntityName, formatPhoneNumber } from '@shared/utils/entity';
import SettingIcon from '@shared/assets/icons/SettingIcon';
import TableLeadCategories from './TableLeadCategories';
import RowLeadCategories from './RowLeadCategories';

import DriverSimpleDescriptions from '@features/list-driver/ui/DriverSimpleDescriptions';
import SellerSimpleDescriptions from '@features/list-seller/ui/SellerSimpleDescriptions';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import { StatusTag } from '@shared/ui';
import ModalLeadForm from './ModalLeadForm';
import ModalLeadCopyForm from './ModalLeadCopyForm';

import { timestampToNormalDDMMYY_HHMM } from '@shared/utils/tsToTime';
import EnterToEntityButton from '@widgets/Button/EnterToEntityButton';
import ModalButtonRatingCreateToEntityWithSMS from '@features/list-rating/ui/ModalButtonRatingCreateToEntityWithSMS';
import CanDo from '@shared/lib/CanDo';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange,
   getColumnSearchDriverProps
} from '@shared/ui/Table';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import { changeUrl } from '@shared/utils/browser';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import CanDoManager from '@shared/lib/CanDoManager';
import { getUserAuthData } from '@entitles/User';
import { useSelector } from 'react-redux';
import ExportToCSVModalButton from '@widgets/ExportToCSV/ExportToCSVModalButton';
import {
   StatusesDateTimeLine,
   StatusesDateTimeLineXronology
} from '@widgets/Table/StatusesDateTimeLine';

import UserCardById from '@features/list-user/ui/UserCardById';

const { Text } = Typography;

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const TableLeads = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedLead, setSelectedLead] = useState(null);
   const [selectedNote, setSelectedNote] = useState(null);
   const [selectedCopyLead, setSelectedCopyLead] = useState(null);
   const userData = useSelector(getUserAuthData);
   const navigate = useNavigate();

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const params = parseUrlListSettings();

         fetchData(params);
      }
   }, []);

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         fixed: 'left',
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         filtered: !!filters?.['id'],
         width: '110px',
         filteredValue: filters?.['id'] || null,
         render: (_, record) => (
            <Space direction="vertical">
               <Space direction="horizontal">
                  <Tooltip placement="top" title={'Открыть заявку'}>
                     <span
                        className="green-span-url"
                        style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                        onClick={() => navigate(`/leads/${_}`)}>
                        {`${_}`}
                     </span>
                  </Tooltip>

                  <NewEntityTag date={record.createdAt} />
               </Space>

               {/* <Tag bordered={false}>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                     {record.materialCategory.name}:{' '}
                     {record?.capacity
                        ? `${record.capacity} ${suffix(
                             record.materialCategory.unit
                          )}`
                        : `Не указан вес`}
                  </Text>
               </Tag> */}

               {/* {record?.materialCategory?.name && (
                  <Tag color={stringToColor(record.materialCategory.name)}>
                     {record.materialCategory.name}:{' '}
                     {record?.capacity && `${record.capacity} кг`}
                  </Tag>
               )} */}
            </Space>
         ),
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      },

      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         width: '180px',
         align: 'left',
         filterMode: true,
         filteredValue: Array.isArray(paramsTable?.filters?.status)
            ? paramsTable?.filters?.status
            : paramsTable?.filters?.status
            ? [paramsTable?.filters?.status]
            : [],
         filters: Object.values(statusesOfLeads).map((e) => ({
            text: <StatusTag status={e.value} type="lead" />,
            value: e.value
         })),
         // filteredValue: filteredInfo.name || null,
         // onFilter: (value, record) => record.name.includes(value),
         // sorter: (a, b) => a.name.length - b.name.length,
         // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
         // ellipsis: true,
         render: (_, record) => (
            <TooltipCustom
               key={record.id}
               text={
                  <StatusesDateTimeLine
                     createdAt={record.createdAt}
                     updatedAt={record.updatedAt}
                     entityId={record.id}
                     entityType="lead"
                     isReverse={false}
                     isListMode={true}
                  />
               }>
               <span>
                  <StatusTag status={_} type="lead" />
               </span>
            </TooltipCustom>
         )
      },
      {
         title: 'Статус сделки',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         width: '280px',
         render: (_, record) => (
            <>
               {['finished', 'waiting'].includes(record?.status) ? (
                  <>
                     <LeadConfirm
                        id={record.id}
                        status={record.status}
                        callback={() => {}}
                        simple
                     />
                  </>
               ) : (
                  <Space direction="horizontal">
                     <LoadingOutlined /> Сделка в процессе
                  </Space>
               )}
            </>
         )
      },
      // {
      //    title: 'Заявка',
      //    dataIndex: 'id',
      //    key: 'id',
      //    render: (_) => (
      //       <Space size="small" wrap>
      //          {`Заявка №${_}`}
      //       </Space>
      //    )
      // },
      // {
      //    title: "Район",
      //    dataIndex: "districtId",
      //    key: "districtId",
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          {record?.addresses?.districtInfo?.city_district}
      //       </Space>
      //    ),
      //    filtered: !!filters?.["districtId"],
      //    ...getConstColumnSearchFilterProps({
      //       options: districtList,
      //       dataIndex: "districtId",
      //       handleSearch: (searchObj) => onSearchTable(searchObj),
      //    }),
      // },

      // {
      //    title: 'Дата создания',
      //    dataIndex: 'createdAt',
      //    key: 'createdAt',
      //    sorter: true,
      //    width: '160px',
      //    align: 'left',
      //    sortOrder:
      //       paramsTable?.sorter?.sort === 'createdAt'
      //          ? `${paramsTable?.sorter?.order}end`
      //          : null,
      //    render: (_) => timestampToNormalDDMMYY_HHMM(_)
      // },
      {
         title: 'Продавец',
         dataIndex: 'addresses',
         key: 'addresses',
         width: '250px',
         render: (_, record) => (
            <Space size="small" wrap>
               <div className="address_list">
                  {record?.address?.seller && (
                     <>
                        <TooltipCustom
                           text={
                              <SellerSimpleDescriptions
                                 data={record?.address?.seller}
                              />
                           }>
                           <div>
                              {getEntityName(record?.address?.seller)}{' '}
                              <Tooltip placement="top" title={'Открыть продавца'}>
                                 <ArrowRightOutlined
                                    style={{ marginLeft: '5px', fontSize: '12px' }}
                                    onClick={() =>
                                       navigate(
                                          `/sellers/${record?.address?.seller?.id}`
                                       )
                                    }
                                 />
                              </Tooltip>
                           </div>
                           <div>
                              <Text type="secondary">
                                 {formatPhoneNumber(
                                    record?.address?.seller?.mobileNumber
                                 )}
                              </Text>
                           </div>
                        </TooltipCustom>
                     </>
                  )}
               </div>
            </Space>
         )
      },
      {
         title: 'Адрес',
         dataIndex: 'addresses',
         width: '220px',
         key: 'addresses',
         render: (_, record) => (
            <Space size="small" wrap>
               <div className="address_list">
                  <>
                     <TooltipCustom
                        text={
                           <Descriptions size="small">
                              {record?.address?.address ? (
                                 <Descriptions.Item
                                    span={3}
                                    label="Адрес">{`Адрес: ${record?.address?.address}`}</Descriptions.Item>
                              ) : null}
                              {record?.address?.districtName ? (
                                 <Descriptions.Item span={3} label="Район">
                                    {record?.address?.districtName}
                                 </Descriptions.Item>
                              ) : null}
                              {record?.address?.typeOfAddress ? (
                                 <Descriptions.Item span={3} label="Тип места">
                                    {record?.address?.typeOfAddress}
                                 </Descriptions.Item>
                              ) : null}

                              {record?.address?.comment ? (
                                 <Descriptions.Item span={3} label="Комментарий">
                                    {record?.address?.comment}
                                 </Descriptions.Item>
                              ) : null}
                           </Descriptions>
                        }>
                        <div>{record?.address?.street}</div>
                        <Text type="secondary">{record?.address?.districtName}</Text>
                        {/* <div style={{ whiteSpace: 'nowrap' }}>
                           <TooltipTextWithEllipsis
                              text={record?.address?.address}
                              maxLength={24}
                              mainText={record?.address?.street}
                           />
                        </div> */}
                     </TooltipCustom>
                  </>
               </div>
            </Space>
         )
      },
      {
         title: 'Сырье',
         dataIndex: 'entityCategories',
         key: 'entityCategories',
         align: 'left',
         width: '280px',
         render: (_, record) => (
            <>
               <TooltipCustom
                  text={<TableLeadCategories data={_} />}
                  overlayClassName="tooltip-table-categories">
                  <span>
                     <RowLeadCategories data={_} />
                  </span>
               </TooltipCustom>
            </>
         )
      },

      {
         title: 'Водитель',
         dataIndex: 'driverId',
         key: 'driverId',
         width: '250px',
         filtered: !!filters?.['driverId'],
         filterMode: true,
         filteredValue: filters?.['driverId'] || null,
         ...getColumnSearchDriverProps({
            dataIndex: 'driverId',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),

         render: (_, record) => (
            <Space size="small" wrap>
               <div className="address_list">
                  {record?.driver ? (
                     <>
                        <TooltipCustom
                           text={<DriverSimpleDescriptions data={record?.driver} />}>
                           <div>
                              {getEntityName(record?.driver)}{' '}
                              <Tooltip placement="top" title={'Открыть водителя'}>
                                 <ArrowRightOutlined
                                    style={{ marginLeft: '5px', fontSize: '12px' }}
                                    onClick={() =>
                                       navigate(`/drivers/${record?.driver?.id}`)
                                    }
                                 />
                              </Tooltip>
                           </div>
                           <div>
                              <Text type="secondary">
                                 {formatPhoneNumber(record?.driver?.mobileNumber)}
                              </Text>
                           </div>
                        </TooltipCustom>
                     </>
                  ) : (
                     'Не назначен'
                  )}
               </div>
            </Space>
         )
      },
      // {
      //    title: 'Стоимость',
      //    dataIndex: 'price',
      //    key: 'price',
      //    width: '180px',
      //    render: (_, record) => (
      //       <Space size="small" direction="vertical" wrap>
      //          {_
      //             ? `${_} ${record.priceType === 'unit' ? 'руб/кг' : 'руб'}`
      //             : 'Бесплатно'}

      //          {record.priceType === 'unit' && (
      //             <Tag bordered={false}>
      //                <Text type="secondary" style={{ fontSize: '12px' }}>
      //                   {_
      //                      ? `Общая: ${`${record?.finalPrice} руб` || 'неизвестно'}`
      //                      : ''}
      //                </Text>
      //             </Tag>
      //          )}
      //       </Space>
      //    )
      // },
      {
         title: 'Даты вывоза',
         dataIndex: 'leadsTimes',
         key: 'leadsTimes',
         align: 'left',
         width: '260px',
         render: (_, record) => <LeadTimesList data={record?.leadsTimes} />
      }
   ];

   const actions = [
      // {
      //    title: 'Продавец',
      //    dataIndex: 'addresses',
      //    width: '360px',
      //    key: 'addresses',
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          <div className="address_list">
      //             <List
      //                itemLayout="horizontal"
      //                dataSource={[record?.address?.seller || []]}
      //                renderItem={(item) => (
      //                   <List.Item key={item.toString()}>
      //                      {item?.firstName && (
      //                         <List.Item.Meta
      //                            title={`${item.firstName} ${item.lastName}`}
      //                            description={
      //                               <>
      //                                  <div>{record?.address?.address}</div>
      //                                  <div>
      //                                     Телефон:{' '}
      //                                     {record?.address?.seller?.mobileNumber}
      //                                  </div>{' '}
      //                                  {record?.address?.districtName ? (
      //                                     <div>{`Район: ${record?.address?.districtName}`}</div>
      //                                  ) : null}
      //                                  {record?.address?.typeOfAddress ? (
      //                                     <div>{`Место вывоза: ${record?.address?.typeOfAddress}`}</div>
      //                                  ) : null}
      //                               </>
      //                            }
      //                            style={{
      //                               display: 'flex',
      //                               flexDirection: 'column',
      //                               width: '100%'
      //                            }}
      //                         />
      //                      )}
      //                   </List.Item>
      //                )}
      //             />
      //          </div>
      //       </Space>
      //    )
      // },

      // {
      //    title: 'Ответственный',
      //    dataIndex: 'userId',
      //    width: '200px',
      //    key: 'userId',
      //    filtered: !!filters?.['userId'],
      //    filteredValue: filters?.['userId'] || null,
      //    ...getColumnSearchProps({
      //       dataIndex: 'userId',
      //       handleSearch: (searchObj) => onSearchTable(searchObj),
      //       type: 'number'
      //    }),
      //    render: (userId) => <UserCardById id={userId} />
      // },
      // {
      //    title: 'Хронология событий',
      //    dataIndex: 'updatedAt',
      //    key: 'updatedAt',
      //    width: '320px',
      //    align: 'left',
      //    render: (_, record) => (
      //       <StatusesDateTimeLineXronology
      //          createdAt={record.createdAt}
      //          updatedAt={record.updatedAt}
      //          entityId={record.id}
      //          entityType="lead"
      //          isReverse={true}
      //       />
      //    )
      // },
      // {
      //    title: 'Действие',
      //    key: 'action',
      //    width: 200,
      //    fixed: 'right',
      //    render: (_, record) => (
      //       <Space size="middle">
      //          <EnterToEntityButton id={record.id} entity="leads" />
      //          <CanDo permission="can_edit_leads">
      //             <Space size="middle">
      //                <CanDoManager userId={record.userId}>
      //                   <Tooltip placement="top" title={'Редактирование'}>
      //                      <Button
      //                         onClick={() => setSelectedLead(record)}
      //                         type="primary">
      //                         <EditOutlined />
      //                      </Button>
      //                   </Tooltip>{' '}
      //                </CanDoManager>

      //                <Tooltip placement="top" title={'Добавить заметку'}>
      //                   <ModalButtonNoteCreate
      //                      closeModal={closeModal}
      //                      entityId={record.id}
      //                      entityType={'lead'}
      //                      buttonText={null}
      //                   />{' '}
      //                </Tooltip>

      //                {record.status === 'finished' && (
      //                   <Tooltip placement="top" title={'Оценить'}>
      //                      <ModalButtonRatingCreateToEntityWithSMS
      //                         leadId={record.id}
      //                         id={record?.rating?.id || null}
      //                         isSendedSmsRating={record.isSendedSmsRating === 1}
      //                         valuesOfRating={{
      //                            actionForEntityType: 'lead',
      //                            actionForEntityId: record?.id,
      //                            fromEntityType: 'seller',
      //                            fromEntityId: record?.address?.seller.id,
      //                            toEntityType: 'driver',
      //                            toEntityId: record?.driver?.id,
      //                            status: 'active',
      //                            categoryOfRating: 1
      //                         }}
      //                         closeModal={fetchData}
      //                      />
      //                   </Tooltip>
      //                )}

      //                {/* {record.status === 'active' && (
      //                   <Tooltip placement="top" title={'Заблокировать'}>
      //                      <Button
      //                         type="primary"
      //                         danger
      //                         onClick={() => updateLeadStatusRequest(record.id)}>
      //                         <StopOutlined />
      //                      </Button>
      //                   </Tooltip>
      //                )} */}

      //                {/* {record.status === 'blocked' && (
      //                   <DeleteEntityButton
      //                      id={record.id}
      //                      callbackFetch={deleteLeadById}
      //                      update={fetchData}
      //                      permission="can_delete_leads"
      //                   />
      //                )} */}
      //             </Space>
      //          </CanDo>
      //       </Space>
      //    )
      // },
      {
         title: <SettingIcon />,
         key: 'action',
         align: 'center',
         width: '70px',
         fixed: 'right',
         render: (_, record) => (
            <Dropdown
               menu={{
                  items: [
                     {
                        icon: <ExportOutlined />,
                        label: 'Открыть',
                        onClick: () => navigate(`/leads/${record.id}`)
                     },
                     {
                        icon: <EditOutlined />,
                        label: 'Редактировать',
                        onClick: () => setSelectedLead(record)
                     },
                     {
                        icon: <CopyOutlined />,
                        label: 'Копировать',
                        onClick: () => setSelectedCopyLead(record)
                     },
                     {
                        icon: <BarsOutlined />,
                        label: 'Заметки',
                        onClick: () => setSelectedNote(record.id)
                     }
                  ]
               }}
               placement="bottomRight"
               trigger={['click']}>
               <Button icon={<DashOutlined rotate={90} />} type="text" />
            </Dropdown>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   // const updateLeadStatusRequest = (leadId) => {
   //    UpdateStatusOfLead({ status: 'blocked' }, leadId).then(() => fetchData());
   // };

   const closeModal = () => {
      setSelectedLead(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const closeCopyModal = () => {
      setSelectedCopyLead(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const closeNoteCreate = () => {
      setSelectedNote(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      const queryObj = getApiQueryParamsForTables(
         params,
         paramsTable,
         initialSorter
      );
      const { page: current, limit: pageSize } = queryObj;

      GetLeadList(queryObj).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, current, pageSize, total: res.count }
         });

         changeUrl(params);
         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   const showOnlyMy = (bool) => {
      const newParams = {
         ...paramsTable,
         filters: {
            ...paramsTable.filters,
            userId: userData.id.toString()
         }
      };

      if (!bool) {
         delete newParams.filters.userId;
      }

      fetchData(newParams);
   };

   return (
      <>
         <ModalLeadForm selectedLead={selectedLead} closeModal={closeModal} />
         <ModalLeadCopyForm
            selectedLead={selectedCopyLead}
            closeModal={closeCopyModal}
         />

         <ModalListOfNotes
            entityId={selectedNote}
            entityType={'lead'}
            closeModal={closeNoteCreate}
            hideTitle={true}
         />

         <CanDo permission="can_create_leads">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonLeadCreate closeModal={closeModal} />
               <ModalButtonLeadsMap />
               <Space style={{ marginLeft: '10px' }} size="small">
                  Показать
                  <Switch
                     checkedChildren="Мои"
                     unCheckedChildren="Все"
                     checked={filters.userId === userData.id.toString()}
                     onChange={showOnlyMy}
                  />
               </Space>
            </Space>
         </CanDo>
         <VerticalSpace />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
                  <ExportToCSVModalButton
                     fetchData={GetLeadListExcel}
                     dataParams={paramsTable}
                  />
               </Space>
            </div>
         </div>
         <div className="table-block">
            <Table
               columns={[...columns, ...actions]}
               dataSource={data}
               pagination={false}
               rowKey="id"
               onChange={handleTableChange}
               loading={isLoading}
               scroll={{ x: 1500 }}
            />
         </div>
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableLeads;
